import React from 'react';
import { colorify } from 'lottie-colorify';
import { useLoading } from '../../context/QueryLoadingContext';
import './QueryLoader.scss';
import Lottie from '../loading/Lottie';
import LoadingLottie from '../../assets/lotties/loading.json';

function QueryLoader() {
  const { loading } = useLoading();

  if (!loading) return null;

  return (
    <div className="loader-overlay">
      <div className="loader-container">
        <Lottie animationData={colorify(['#FF8795', '#FFC3C5', '#FFF3E6'], LoadingLottie)} height={100} width={100} />
        <div className="loader">Loading preview...</div>
      </div>
    </div>
  );
}

export default QueryLoader;
