import React, {
  createContext, useState, useContext, useMemo,
} from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line no-unused-vars
const QueryLoadingContext = createContext({ loading: false, setLoading: (isLoading) => {} });

export const useLoading = () => useContext(QueryLoadingContext);

export function LoadingProvider({ children }) {
  const [loadingQueries, setLoadingQueries] = useState(0);

  const setLoading = (isLoading) => {
    setLoadingQueries((prev) => (isLoading ? prev + 1 : Math.max(0, prev - 1)));
  };

  const loading = loadingQueries > 0;
  const loadingMemo = useMemo(() => ({ loading, setLoading }), [loading, setLoading]);

  return (
    <QueryLoadingContext.Provider value={loadingMemo}>
      {children}
    </QueryLoadingContext.Provider>
  );
}

LoadingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
