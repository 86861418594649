import PropTypes from 'prop-types';
import React from 'react';
import { colorify } from 'lottie-colorify';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { Link } from 'gatsby-link';
import Arrow from '../../assets/icons/arrow.svg';
import LoadingLottie from '../../assets/lotties/loading.json';
import Lottie from '../loading/Lottie';

import './Button.scss';

export default function Button({
  text,
  displayArrow,
  className,
  submitButton,
  loading,
  disabled,
  target,
  link,
  onClick,
}) {
  const isExternalLink = link && (link.startsWith('http') || link.includes('.pdf'));
  const isAnchorLink = link && !isExternalLink && link.includes('#');

  function handleOnClick() {
    if (onClick) {
      onClick();
    }
  }

  function getTag() {
    if (isExternalLink) {
      return 'a';
    }
    if (link) {
      return link.includes('#') ? AnchorLink : Link;
    }

    return 'button';
  }

  function getType() {
    if (link) {
      return undefined;
    }

    return submitButton ? 'submit' : 'button';
  }

  const Tag = getTag();

  const onClickEvent = isAnchorLink
    ? { onAnchorLinkClick: handleOnClick } : { onClick: handleOnClick };

  return (
    <Tag
      type={getType()}
      className={`button ${className} ${disabled && 'disabled'} ${loading && 'loading'}`}
      disabled={loading || disabled}
      to={link && !isExternalLink ? link : undefined}
      href={isExternalLink ? link : undefined}
      target={target}
      {...onClickEvent}
    >
      {loading ? (
        <Lottie animationData={colorify(['#FF8795', '#FFC3C5', '#FFF3E6'], LoadingLottie)} height={30} width={30} />
      ) : (
        <>
          <span>{text}</span>
          {displayArrow && <Arrow width="18px" height="18px" stroke="#ffffff" />}
        </>
      )}
    </Tag>
  );
}

Button.defaultProps = {
  displayArrow: false,
  className: null,
  submitButton: false,
  loading: false,
  disabled: false,
  target: '_self',
  link: null,
  onClick: null,
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  displayArrow: PropTypes.bool,
  className: PropTypes.string,
  submitButton: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  target: PropTypes.string,
  link: PropTypes.string,
  onClick: PropTypes.func,
};
